import { BehaviorSubject } from 'rxjs';
import { IStorage, LocalStorage } from '../services/storage/storage.service';


export class PresistedBehaviorSubject<T> extends BehaviorSubject<T> {
	private storage: IStorage;

	constructor(private key: string, defaultValue: T) {
		const storage = new LocalStorage();
		const storedValue = storage.get<T>(key) ?? defaultValue;

		super(storedValue);

		this.storage = storage;
		this.storage.set(this.key, storedValue);

		this.subscribe(value => {
			this.storage.set(this.key, value);
		});
	}
}
