
import { AppInjector } from 'app/app.component';
import { NotificationService } from '@shared/services';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export function toast<T>(severity: "success" | "info" | "warn" | "error", summary: string, detail: string) {
	const messageService = AppInjector.get(NotificationService);

	return (source: Observable<T>) => source.pipe(
		tap(() => messageService.add({ severity, summary, detail }))
	);
}
