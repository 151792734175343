import { HttpResponse } from '@angular/common/http';
import { Observable, OperatorFunction } from 'rxjs';

export function readBlobAsJson<T>(): OperatorFunction<HttpResponse<Blob>, T> {
	return (source: Observable<HttpResponse<Blob>>) =>
		new Observable<T>(subscriber => {
			source.subscribe({
				next: response => {
					const blob = response.body;
					if (!blob) {
						subscriber.error(new Error('No blob found in response'));
						return;
					}

					const reader = new FileReader();

					reader.onload = () => {
						try {
							const text = reader.result as string;
							const jsonObject = JSON.parse(text) as T;
							subscriber.next(jsonObject);
							subscriber.complete(); // Complete after emitting the parsed object
						} catch (err) {
							subscriber.error(new Error('Failed to parse JSON from blob'));
						}
					};

					reader.onerror = () => {
						subscriber.error(new Error('Failed to read the blob'));
					};

					reader.readAsText(blob);
				},
				error: err => subscriber.error(err),
				complete: () => {
					// Don't complete here, the completion should happen only after FileReader completes
				}
			});
		});
}
